import Vue from 'vue';
import VueRouter from 'vue-router';
import { AssemblyTemplateSharedRoutes, HardwareListSharedRoutes, HardwareListTemplateSharedRoutes, NonnominalSharedRoutes, OrganizationSharedRoutes, UserSharedRoutes } from '~/nasa_ui/router';
import { AdminSharedRoutes } from '~/nasa_ui/router/admin';
import { CosmicUserSupportContext, EntityType, UserPermission } from '~/nasa_ui/types';
import { RouterLogUtility } from '../utils/RouterLogUtility';
Vue.use(VueRouter);
// Delivery matrix
const DeliveryMatrixRoutes = [
    {
        path: `/${EntityType.DELIVERY_MATRIX_ITEM}/search`,
        name: 'DeliveryMatrixItemSearchView',
        component: () => import(
        /* webpackChunkName: "Logistics" */ '../views/DeliveryMatrixItemSearchView/DeliveryMatrixItemSearchView.vue'),
        meta: {
            displayName: 'Delivery matrix',
            description: 'Delivery matrix search',
            permissions: [UserPermission.DELIVERY_MATRIX_ITEM_ADMIN],
            supportContexts: [CosmicUserSupportContext.ESOC],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.DELIVERY_MATRIX_ITEM}/manage/:id`,
        name: 'DeliveryMatrixItemManageView',
        component: () => import(
        /* webpackChunkName: "Logistics" */ '../views/DeliveryMatrixItemManageView/DeliveryMatrixItemManageView.vue'),
        meta: {
            displayName: 'COSMIC - Delivery matrix manage',
            description: 'Delivery matrix manage',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [UserPermission.DELIVERY_MATRIX_ITEM_ADMIN],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.DELIVERY_MATRIX_ITEM}/create`,
        name: 'DeliveryMatrixItemCreateView',
        component: () => import(
        /* webpackChunkName: "Logistics" */ '../views/DeliveryMatrixItemCreateView/DeliveryMatrixItemCreateView.vue'),
        meta: {
            displayName: 'COSMIC - Delivery matrix creation',
            description: 'Delivery matrix creation',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [UserPermission.DELIVERY_MATRIX_ITEM_ADMIN],
            userRoles: []
        }
    },
    {
        path: `/${EntityType.DELIVERY_MATRIX_ITEM}/tasks`,
        name: 'DeliveryMatrixItemTaskOwnerView',
        component: () => import(
        /* webpackChunkName: "Logistics" */ '../views/DeliveryMatrixItemTaskOwnerView/DeliveryMatrixItemTaskOwnerView.vue'),
        meta: {
            displayName: 'COSMIC - Delivery matrix items manage',
            description: 'Delivery matrix items manage',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [UserPermission.DELIVERY_MATRIX_ITEM_ADMIN],
            userRoles: []
        }
    }
];
// Delivery order routes
const DeliveryOrderRoutes = [
    {
        path: `/delivery_orders/search`,
        name: 'DeliveryOrderSearchView',
        component: () => import(/* webpackChunkName: "Logistics" */ '../views/DeliveryOrderSearchView/DeliveryOrderSearchView.vue'),
        meta: {
            displayName: 'Delivery order',
            description: 'Delivery orders search',
            permissions: [UserPermission.LOGISTICS_ADMIN],
            supportContexts: [CosmicUserSupportContext.ESOC],
            userRoles: []
        }
    },
    {
        path: `/delivery_orders/manage/:id`,
        name: 'DeliveryOrderManageView',
        component: () => import(/* webpackChunkName: "Logistics" */ '../views/DeliveryOrderManageView/DeliveryOrderManageView.vue'),
        meta: {
            displayName: 'COSMIC - Delivery orders manage',
            description: 'Delivery orders manage',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [UserPermission.LOGISTICS_ADMIN],
            userRoles: []
        }
    },
    {
        path: `/delivery_orders/create`,
        name: 'DeliveryOrderCreateView',
        component: () => import(/* webpackChunkName: "Logistics" */ '../views/DeliveryOrderCreateView/DeliveryOrderCreateView.vue'),
        meta: {
            displayName: 'COSMIC - Delivery orders creation',
            description: 'Delivery orders creation',
            supportContexts: [CosmicUserSupportContext.ESOC],
            permissions: [UserPermission.LOGISTICS_ADMIN],
            userRoles: []
        }
    }
];
// Logistic routes
const LogisticsRoutes = [
    {
        path: `/critical_inventory_tracking/:hardwareListId?`,
        name: 'CriticalInventoryTrackingHomeView',
        component: () => import(
        /* webpackChunkName: "Logistics" */ '../views/CriticalInventoryTrackingHomeView/CriticalInventoryTrackingHomeView.vue'),
        meta: {
            description: 'Home page for the module called Critical Inventory Tracking',
            displayName: 'Critical Inventory Tracking',
            permissions: [UserPermission.LOGISTICS_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/chicklet`,
        name: 'ChickletView',
        component: () => import(/* webpackChunkName: "Logistics" */ '../views/ChickletView/ChickletView.vue'),
        meta: {
            description: '"Chicklet" view',
            displayName: 'Chicklet view',
            permissions: [UserPermission.LOGISTICS_ADMIN],
            supportContexts: [],
            userRoles: []
        }
    }
];
export const ReportingRoutes = [
    {
        path: `/reporting`,
        name: 'ReportingSearchView',
        component: () => import(/* webpackChunkName: "Reporting" */ '../views/ReportingSearchView/ReportingSearchView.vue'),
        meta: {
            displayName: 'Reporting search',
            description: 'Listing of every available COSMIC Logistics report.',
            doNotList: true,
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/delivery_matrix_change_logs`,
        name: 'ReportDeliveryMatrixItemChangeLogView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/ReportDeliveryMatrixItemChangeLogView/ReportDeliveryMatrixItemChangeLogView.vue'),
        meta: {
            description: 'Full history of tracked fields for the given Delivery Matrix item.',
            doNotList: false,
            displayName: 'Delivery Matrix change logs',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    {
        path: `/reporting/delivery_matrix_change_logs/results`,
        name: 'ReportDeliveryMatrixItemChangeLogResultsView',
        component: () => import(
        /* webpackChunkName: "Reporting" */ '../views/ReportDeliveryMatrixItemChangeLogView/ReportDeliveryMatrixItemChangeLogResultsView.vue'),
        meta: {
            description: 'Full history of tracked fields for the given Delivery Matrix item.',
            doNotList: true,
            displayName: 'Delivery Matrix change logs',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    }
];
const ALL_ROUTES = [
    {
        path: `/`,
        name: 'HomeView',
        component: () => import(/* webpackChunkName: "Logistics" */ '../views/HomeView/HomeView.vue'),
        meta: {
            displayName: 'Logistics Home',
            description: 'Logistics home page',
            permissions: [],
            supportContexts: [],
            userRoles: []
        }
    },
    ...AdminSharedRoutes,
    ...AssemblyTemplateSharedRoutes,
    ...DeliveryMatrixRoutes,
    ...DeliveryOrderRoutes,
    ...HardwareListSharedRoutes,
    ...HardwareListTemplateSharedRoutes,
    ...LogisticsRoutes,
    ...OrganizationSharedRoutes,
    ...ReportingRoutes,
    ...UserSharedRoutes,
    ...NonnominalSharedRoutes // needs to be last
];
export default new RouterLogUtility(VueRouter, ALL_ROUTES);
