var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { CommentType } from '~/db_types';
import { DeliveryMatrixItemResponse, DeliveryMatrixItemStatus, DeliveryMatrixItemType, NeedType } from '~/db_types/swagger_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { NOT_APPLICABLE } from '~/nasa_ui/constants/display';
import { AS_BUILT_COL, BASE_ICON_COL, BASE_NUMBER_COL, BASE_TEXT_COL, CLASS_COL, DESCRIPTION_COL, DRAWING_NUMBER_COL, QUANTITY_COL, SERIAL_COL, SIDE_COL, SIZE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { CommentsRepoMixin } from '~/nasa_ui/DAL/comment';
import { ItemInstanceRepoMixin } from '~/nasa_ui/DAL/itemInstance';
import { LocationRepoMixin } from '~/nasa_ui/DAL/location';
import { OrganizationRepoMixin } from '~/nasa_ui/DAL/organization';
import { AlertType, Maybe, UserPermission } from '~/nasa_ui/types';
import { DeliveryMatrixItemRequirementsDocument, DeliveryMatrixItemRequirementsDocumentDisplay, DeliveryMatrixItemTypeDisplay, DeliveryMatrixNeedTypeDisplay, DeliveryMatrixOrgCodes } from '~/nasa_ui/types/enums/delivery-matrix-item';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { sortObjectNumericallyBy } from '~/nasa_ui/utils/helpers/sortObjectNumericallyBy';
import { transformDeliveryMatrixItems } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let TabDeliveryMatrixItemInformation = class TabDeliveryMatrixItemInformation extends Mixins(BaseTab, CommentsRepoMixin, ItemInstanceRepoMixin, LocationRepoMixin, OrganizationRepoMixin) {
    DeliveryMatrixItemRequirementsDocument = DeliveryMatrixItemRequirementsDocument;
    DeliveryMatrixItemRequirementsDocumentDisplay = DeliveryMatrixItemRequirementsDocumentDisplay;
    DeliveryMatrixItemType = DeliveryMatrixItemType;
    DeliveryMatrixItemTypeDisplay = DeliveryMatrixItemTypeDisplay;
    NeedType = NeedType;
    NOT_APPLICABLE = NOT_APPLICABLE;
    DeliveryMatrixNeedTypeDisplay = DeliveryMatrixNeedTypeDisplay;
    selectableOrganizations = [];
    selectedHardwareItem = null;
    selectedLocation = null;
    selectedTemplateSlot = null;
    showCompletionModal = false;
    formData = {
        deliverableItemsListNumber: null,
        deliveryDate: null,
        description: null,
        dueDate: null,
        estimatedCompletionDate: null,
        helpNeededFromNasa: false,
        helpNeededFromNasaText: null,
        isHardware: true,
        logisticsNeedDate: null,
        modificationNumber: null,
        needType: null,
        originalEstimatedCompletionDate: null,
        requirementsDocumentNumber: null,
        status: null,
        subType: null,
        taskOwnerId: null,
        taskOwnerUpdateNeeded: false,
        workBreakdownStructureNumber: null
    };
    overrideHardwareListAssemblyTableHeaders = [
        composeTableHeader(BASE_ICON_COL, {
            text: 'Hw type',
            value: '_hardwareTypeIcon'
        }),
        composeTableHeader(QUANTITY_COL, {
            noWrap: true,
            width: '100px'
        }),
        composeTableHeader(DRAWING_NUMBER_COL, {
            text: `Drawing<br/>number`,
            value: '_computedDrawingNumber'
        }),
        composeTableHeader(AS_BUILT_COL, {
            value: '_computedAsBuiltNumber'
        }),
        composeTableHeader(DESCRIPTION_COL, {
            noWrap: true,
            value: '_computedDescription'
        }),
        composeTableHeader(SIDE_COL, {
            value: '_computedSide'
        }),
        composeTableHeader(SIZE_COL, {
            value: '_computedSize'
        }),
        composeTableHeader(SERIAL_COL, {
            value: '_computedSerialNumber'
        }),
        composeTableHeader(CLASS_COL, {
            value: '_computedSubType'
        }),
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'CEI',
            value: '_computedContractEndingItemNumber'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            noEscape: true,
            text: 'Location',
            value: '_computedLocation'
        })
    ];
    deliveryMatrixItem;
    get changeLogsReportUrl() {
        if (!this.deliveryMatrixItem) {
            return null;
        }
        return `/reporting/delivery_matrix_change_logs/results?deliveryMatrixItemId=${this.deliveryMatrixItem.id}`;
    }
    get childrenToTheSelectedHardwareList() {
        return this.deliveryMatrixItem?.hardwareList?.assemblies || [];
    }
    get computedNextSequenceNumber() {
        if (!this.childrenToTheSelectedHardwareList.length) {
            return 10;
        }
        return ((this.childrenToTheSelectedHardwareList.sort(sortObjectNumericallyBy('sequence', true))[0]?.sequence ?? 0) + 1);
    }
    get deliveryMatrixItemPatch() {
        return {
            attributes: this.deliveryMatrixItem?.attributes,
            deliverableItemsListNumber: this.formData.deliverableItemsListNumber,
            deliveryDate: this.formData.deliveryDate,
            deliveryDestinationId: this.selectedLocationId,
            description: this.formData.description || '',
            dueDate: this.formData.dueDate,
            hardwareListId: this.deliveryMatrixItem?.hardwareList?.id || null,
            helpNeededFromNasa: false,
            helpNeededFromNasaText: this.formData.helpNeededFromNasaText,
            isHardware: this.formData.isHardware ?? false,
            logisticsNeedDate: this.formData.logisticsNeedDate,
            modificationNumber: this.formData.modificationNumber,
            originalEstimatedCompletionDate: this.formData.originalEstimatedCompletionDate,
            requiredQuantity: 0,
            requirementsDocumentNumber: this.formData.requirementsDocumentNumber,
            status: this.formData.status || DeliveryMatrixItemStatus.VOID,
            subType: this.formData.subType || null,
            taskOwnerContactId: this.formData.taskOwnerId,
            taskOwnerUpdateNeeded: this.formData.taskOwnerUpdateNeeded,
            workBreakdownStructureNumber: this.formData.workBreakdownStructureNumber
        };
    }
    get deliveryMatrixItemHardwareListId() {
        return this.deliveryMatrixItem?.hardwareListId ?? null;
    }
    get existingInventoryValues() {
        return this.childrenToTheSelectedHardwareList.map((node) => node?.inventory?.id).filter((node) => Boolean(node));
    }
    get hasDeliveryDateChanged() {
        return this.$dateDisplay(this.deliveryMatrixItem?.deliveryDate) !== this.$dateDisplay(this.formData.deliveryDate);
    }
    get hasDueDateChanged() {
        return this.$dateDisplay(this.deliveryMatrixItem?.dueDate) !== this.$dateDisplay(this.formData.dueDate);
    }
    get hasEstimatedCompletionDateChanged() {
        return (this.$dateDisplay(this.deliveryMatrixItem?.estimatedCompletionDate) !==
            this.$dateDisplay(this.formData.estimatedCompletionDate));
    }
    get hasHardwareListTemplateAdminPermission() {
        return this.currentUserHasPermission(UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN);
    }
    get hardwareListTemplateSlots() {
        return this.deliveryMatrixItem?.hardwareList?.hardwareListTemplateId || [];
    }
    get hasNeedTypeChanged() {
        return this.deliveryMatrixItem?.needType !== this.formData.needType;
    }
    get isFormValid() {
        return (this.formData.subType &&
            this.formData.requirementsDocumentNumber &&
            this.formData.modificationNumber &&
            this.formData.deliverableItemsListNumber &&
            this.formData.description &&
            this.formData.status &&
            this.formData.needType);
    }
    get isItemInProcess() {
        return this.deliveryMatrixItem?.status === DeliveryMatrixItemStatus.IN_PROCESS;
    }
    get isItemCompleted() {
        return this.deliveryMatrixItem?.status === DeliveryMatrixItemStatus.COMPLETED;
    }
    get isItemVoid() {
        return this.deliveryMatrixItem?.status === DeliveryMatrixItemStatus.VOID;
    }
    get needTypeSelectOptions() {
        return Object.values(NeedType).map((needType) => ({
            displayText: DeliveryMatrixNeedTypeDisplay.get(needType) || needType,
            value: needType
        }));
    }
    get legacyNeedValues() {
        return this.deliveryMatrixItem?.attributes.legacyNeedValues || DEFAULT_DASH;
    }
    get selectableOrganizationsSelectOptions() {
        return this.selectableOrganizations.map((org) => ({
            displayText: org.name,
            value: org.code
        }));
    }
    get selectedLocationId() {
        return this.selectedLocation?.id || null;
    }
    get shouldDisableAddToListButton() {
        if (!this.deliveryMatrixItem) {
            return true;
        }
        return this.deliveryMatrixItem.status === DeliveryMatrixItemStatus.COMPLETED;
    }
    get shouldShowLegacyNeedValues() {
        return Boolean(this.legacyNeedValues && !this.deliveryMatrixItem?.needType);
    }
    get transformedDeliveryMatrixItem() {
        return transformDeliveryMatrixItems([this.deliveryMatrixItem])[0];
    }
    async created() {
        this.$listen(`reload_${this.$route.params.id}`, () => this.emitRefetchEntity());
        this.fetchSelectableOrganizations();
    }
    async fetchSelectableOrganizations() {
        try {
            const codes = Object.values(DeliveryMatrixOrgCodes);
            const promises = codes.map((code) => {
                return this.$http.get(`/organizations/${code}`);
            });
            const results = await Promise.all(promises);
            this.selectableOrganizations = results.map((res) => res.data);
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async initializeFormData(item) {
        // top-level data
        this.formData.deliverableItemsListNumber = item.deliverableItemsListNumber || null;
        this.formData.deliveryDate = item.deliveryDate || null;
        this.formData.description = item.description || null;
        this.formData.dueDate = item.dueDate || null;
        this.formData.estimatedCompletionDate = item.estimatedCompletionDate || null;
        this.formData.helpNeededFromNasa = item.helpNeededFromNasa || false;
        this.formData.helpNeededFromNasaText = item.helpNeededFromNasaText || null;
        this.formData.modificationNumber = item.modificationNumber || null;
        this.formData.logisticsNeedDate = item.logisticsNeedDate || null;
        this.formData.needType = item.needType || null;
        this.formData.originalEstimatedCompletionDate = item.originalEstimatedCompletionDate || null;
        this.formData.requirementsDocumentNumber =
            item.requirementsDocumentNumber || null;
        this.formData.status = item.status || null;
        this.formData.subType = item.subType || null;
        this.formData.taskOwnerId = item.taskOwnerContact?.id || null;
        this.formData.workBreakdownStructureNumber = item.workBreakdownStructureNumber || null;
        if (item.deliveryDestination?.id) {
            this.selectedLocation = (await this.getLocationById({ id: item.deliveryDestination.id }))?.locationById || null;
        }
    }
    async onClickOfSave() {
        this.updateDeliveryMatrixItem(this.deliveryMatrixItemPatch);
    }
    onClickOfVoidItem() {
        this.updateDeliveryMatrixItem({ ...this.deliveryMatrixItemPatch, status: DeliveryMatrixItemStatus.VOID });
    }
    onClickOfUnvoidItem() {
        this.updateDeliveryMatrixItem({ ...this.deliveryMatrixItemPatch, status: DeliveryMatrixItemStatus.IN_PROCESS });
    }
    onEntityEdited() {
        this.$notification.add({
            type: AlertType.SUCCESS,
            text: 'Hardware item edited.'
        });
        this.$message(`reload_${this.deliveryMatrixItem?.id}`);
        this.reset();
    }
    async updateDeliveryMatrixItem(item) {
        try {
            if (!this.deliveryMatrixItem) {
                return;
            }
            this.isSaving = true;
            const patch = {
                ...item,
                hardwareListId: this.deliveryMatrixItem.hardwareListId || null
            };
            const resp = await this.$http.put(`/delivery-matrix-item/${this.deliveryMatrixItem.id}`, patch);
            if (resp.data) {
                if (this.hasEstimatedCompletionDateChanged || this.hasNeedTypeChanged) {
                    // must include both regardless of which one changed
                    const changeRequest = {
                        estimatedCompletionDate: this.formData.estimatedCompletionDate,
                        needType: this.formData.needType || undefined
                    };
                    await this.$http.put(`/delivery-matrix-item/${this.deliveryMatrixItem.id}/change-request`, changeRequest);
                }
                if (this.hasDeliveryDateChanged) {
                    await this.createSystemComment(`Delivery date was updated from <code>${this.$dateDisplay(this.deliveryMatrixItem?.deliveryDate)}</code> to <code>${this.$dateDisplay(this.formData?.deliveryDate)}</code> by ${this.currentUserFirstLastNameDisplay}.`);
                }
                if (this.hasDueDateChanged) {
                    await this.createSystemComment(`Due date was updated from <code>${this.$dateDisplay(this.deliveryMatrixItem?.dueDate)}</code> to <code>${this.$dateDisplay(this.formData?.dueDate)}</code> by ${this.currentUserFirstLastNameDisplay}.`);
                }
                // todo: we're making two calls here (plus 2 Comment calls) so we might
                // want to refactor success notifications and/or error messages to
                // support the case where the direct save works but the change request
                // fail.
                this.$notification.add({
                    type: this.AlertType.SUCCESS,
                    text: 'Updated Delivery Matrix item.'
                });
                this.emitRefetchEntity();
                this.scrollToTop();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isSaving = false;
    }
    reset() {
        this.selectedHardwareItem = null;
        this.selectedTemplateSlot = null;
    }
    emitRefetchEntity() { }
    async onChangeOfDeliveryMatrixItem(item) {
        if (!item) {
            return;
        }
        this.initializeFormData(item);
    }
    async updateIsHardware() {
        if (!this.deliveryMatrixItem) {
            return;
        }
        try {
            const resp = await this.$http.put(`/delivery-matrix-item/${this.deliveryMatrixItem.id}`, {
                attributes: this.deliveryMatrixItem.attributes,
                deliverableItemsListNumber: this.deliveryMatrixItem.deliverableItemsListNumber || null,
                deliveryDate: this.deliveryMatrixItem.deliveryDate || null,
                deliveryDestinationId: this.deliveryMatrixItem.deliveryDestination.id,
                description: this.deliveryMatrixItem.description || '',
                dueDate: this.deliveryMatrixItem.dueDate || null,
                hardwareListId: this.deliveryMatrixItem.hardwareListId || null,
                helpNeededFromNasa: this.deliveryMatrixItem.helpNeededFromNasa || false,
                helpNeededFromNasaText: this.deliveryMatrixItem.helpNeededFromNasaText || '',
                isHardware: this.formData.isHardware || true,
                logisticsNeedDate: this.deliveryMatrixItem.logisticsNeedDate || null,
                modificationNumber: this.deliveryMatrixItem.modificationNumber || null,
                originalEstimatedCompletionDate: this.deliveryMatrixItem.originalEstimatedCompletionDate || null,
                requiredQuantity: this.deliveryMatrixItem.requiredQuantity,
                requirementsDocumentNumber: this.deliveryMatrixItem.requirementsDocumentNumber || null,
                status: this.deliveryMatrixItem.status,
                subType: this.deliveryMatrixItem.subType,
                taskOwnerContactId: this.deliveryMatrixItem.taskOwnerContact.id,
                taskOwnerUpdateNeeded: this.deliveryMatrixItem.taskOwnerUpdateNeeded || false,
                workBreakdownStructureNumber: this.deliveryMatrixItem.workBreakdownStructureNumber || null
            });
            if (resp.data) {
                this.$notification.add({
                    type: AlertType.SUCCESS,
                    text: 'Updated.'
                });
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    createSystemComment(commentText) {
        return this.createComment({
            comment: {
                comment: commentText,
                subType: CommentType.SYSTEM,
                deliveryMatrixItemId: this.deliveryMatrixItem?.id
            }
        });
    }
};
__decorate([
    Prop({
        type: Object,
        required: true
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], TabDeliveryMatrixItemInformation.prototype, "deliveryMatrixItem", void 0);
__decorate([
    Emit('refetchEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabDeliveryMatrixItemInformation.prototype, "emitRefetchEntity", null);
__decorate([
    Watch('deliveryMatrixItem', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof DeliveryMatrixItemResponse !== "undefined" && DeliveryMatrixItemResponse) === "function" ? _b : Object]),
    __metadata("design:returntype", Promise)
], TabDeliveryMatrixItemInformation.prototype, "onChangeOfDeliveryMatrixItem", null);
__decorate([
    Watch('formData.isHardware'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TabDeliveryMatrixItemInformation.prototype, "updateIsHardware", null);
TabDeliveryMatrixItemInformation = __decorate([
    Component
], TabDeliveryMatrixItemInformation);
export default TabDeliveryMatrixItemInformation;
