import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { DocumentType } from '~/db_types';
import BaseTab from '~/nasa_ui/base/BaseTab';
import { transformDocuments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let TabDeliveryOrderInformation = class TabDeliveryOrderInformation extends Mixins(BaseTab) {
    DocumentType = DocumentType;
    selectedEntity = null;
    isSaving = false;
    matchingDeliveryOrders = null;
    formData = {
        description: null,
        initiatorContactId: null,
        number: null,
        overrideRevisionNumber: null,
        subType: DocumentType.DELIVERY_ORDER,
        title: null
    };
    get hasRequiredFields() {
        return this.formData.subType && this.formData.number;
    }
    get isArchived() {
        return Boolean(this.selectedEntity?.isArchived);
    }
    get isFormValid() {
        return this.hasRequiredFields;
    }
    get shouldDisableSaveButton() {
        return !this.isFormValid || this.isSaving;
    }
    get transformedSelectedEntity() {
        return this.selectedEntity ? transformDocuments([this.selectedEntity])[0] : null;
    }
    created() {
        this.$listen('reload', this.fetchSelectedEntity);
    }
    async onClickOfSave() {
        if (!this.isFormValid) {
            return;
        }
        try {
            this.isSaving = true;
            const resp = await this.$apollo.mutate({
                mutation: gql `
          mutation SaveDeliveryOrder($input: UpdateDocumentByIdInput!) {
            updateDocumentById(input: $input) {
              document {
                id
              }
            }
          }
        `,
                variables: {
                    input: {
                        id: this.id,
                        patch: {
                            attributes: {},
                            description: this.formData.description,
                            initiatorContactId: this.formData.initiatorContactId,
                            number: this.formData.number,
                            overrideRevisionNumber: this.formData.overrideRevisionNumber,
                            title: this.formData.title
                        }
                    }
                }
            });
            this.$message('reload');
            this.isSaving = false;
        }
        catch (err) {
            this.isSaving = false;
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    async fetchSelectedEntity() {
        try {
            this.isFetching = true;
            const resp = await this.$apollo.query({
                query: gql `
          query GetDeliveryOrderOnInfoTab($id: UUID!) {
            documentById(id: $id) {
              computedRevisionNumber
              description
              id
              initiatorContactId
              number
              overrideRevisionNumber
              subType
              title
            }
          }
        `,
                variables: {
                    id: this.id
                }
            });
            this.selectedEntity = resp.data.documentById;
            this.isFetching = false;
            return resp.data;
        }
        catch (error) {
            this.$errorUtility({ err: error });
            this.isFetching = false;
        }
    }
    onSelectedEntityChanged() {
        if (!this.selectedEntity) {
            return;
        }
        this.formData.description = this.selectedEntity.description;
        this.formData.initiatorContactId = this.selectedEntity.initiatorContactId;
        this.formData.number = this.selectedEntity.number;
        this.formData.overrideRevisionNumber = this.selectedEntity.overrideRevisionNumber;
        this.formData.subType = this.selectedEntity.subType;
        this.formData.title = this.selectedEntity.title;
    }
};
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], TabDeliveryOrderInformation.prototype, "fetchSelectedEntity", null);
__decorate([
    Watch('selectedEntity'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TabDeliveryOrderInformation.prototype, "onSelectedEntityChanged", null);
TabDeliveryOrderInformation = __decorate([
    Component
], TabDeliveryOrderInformation);
export default TabDeliveryOrderInformation;
