var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.selectedEntity && _vm.transformedSelectedEntity ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "mb-2": ""
    }
  }, [_c('c-card', {
    attrs: {
      "border-color": "var(--v-documents-base)"
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('c-icon-fa', {
    staticClass: "pr-2",
    attrs: {
      "icon": _vm.$icons[_vm.DocumentType.DELIVERY_ORDER],
      "icon-color": "var(--v-documents-base)"
    }
  }), _c('span', [_vm._v("General information")])], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": "",
      "pr-2": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "Number",
      "required": "",
      "upper-case": ""
    },
    model: {
      value: _vm.formData.number,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "number", $$v);
      },
      expression: "formData.number"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "pr-2": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "Title",
      "clearable": ""
    },
    model: {
      value: _vm.formData.title,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs2": ""
    }
  }, [_c('AppText', {
    attrs: {
      "label": "Revision"
    },
    model: {
      value: _vm.formData.overrideRevisionNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "overrideRevisionNumber", $$v);
      },
      expression: "formData.overrideRevisionNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "mt-2": ""
    }
  }, [_c('AppMarkdown', {
    attrs: {
      "label": "Description"
    },
    model: {
      value: _vm.formData.description,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": "",
      "mb-2": ""
    }
  }, [_c('AppContact', {
    attrs: {
      "label": "Initiator"
    },
    model: {
      value: _vm.formData.initiatorContactId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "initiatorContactId", $$v);
      },
      expression: "formData.initiatorContactId"
    }
  })], 1)], 1), _c('ButtonSave', {
    attrs: {
      "slot": "actions",
      "disabled": _vm.shouldDisableSaveButton,
      "isSaving": _vm.isSaving
    },
    on: {
      "click": _vm.onClickOfSave
    },
    slot: "actions"
  })], 1)], 1), !_vm.isReadOnly ? _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "text-xs-right": ""
    }
  }, [_c('ArchiveDocument', {
    attrs: {
      "id": _vm.id,
      "is-archived": _vm.isArchived
    }
  })], 1)], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };